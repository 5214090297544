@charset "UTF-8";

/*
#overview
layout
*/

// 共通設定
body {
  font-family: $mincho;
}


// ヘッダー
.l-header {
  // ロゴ & ナビゲーション
  .header-nav {
    position: fixed;
    top: 30px;
    left: 30px;
    z-index: 999;
    @include breakpoint($maxWidth) {
      top: 15px;
    }
    @include bpSp {
      position: static;
      width: 100%;
    }
    .header-logo {
      display: block;
      @include bpSp {
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: center;
      }
      img {
        @include bpSp {
          width: 40%;
        }
      }
    }
    .glnv {
      display: inline-block;
      margin-top: 50px;
      @include bpSp {
        display: none;
      }
      li {
        a {
          display: block;
          padding: 7px 15px 7px 5px;
          background-image: linear-gradient(to right, rgba(0,0,0,0) 50%, $color2 51%);
          background-position: 0 0;
          background-size: 200% auto;
          transition: all .3s ease 0s;
          text-align: center;
          &:hover {
            background-position: -100% 0;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
    }
  }

  // お問い合わせボタン
  .header-btn {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    @include bpSp {
      top: 55vh;
    }
    .m-btn1 {
      width: 156px;
      padding: 6px;
      @include bpSp {
        width: 60px;
        padding: 14.5px;
      }
      &:before {
        content: "";
        position: absolute;
      }
      .icon {
        @include bpSp {
          padding-right: 0;
        }
      }
      .btn-text {
        @include bpSp {
          display: none;
        }
      }
    }
  }

  // SPメニュー
  .sp-menu {
    display: none;
    @include bpSp {
      display: block;
      .btn-gnavi {
        display: none;
        @include bpSp {
          display: block;
          position: fixed;
          top: 67vh;
          right: 0;
          width: 60px;
          height: 60px;
          background-color: #fff;
          transition: all 400ms;
          z-index: 1001;
          span {
            display: inline-block;
            position: absolute;
            right: 30%;
            width: 40%;
            height: 1px;
            background-color: #000;
            border-radius: 4px;
            transition: all 400ms;
            &:nth-of-type(1) {
              top: 22px;
            }
            &:nth-of-type(2) {
              top: 30px;
            }
            &:nth-of-type(3) {
              top: 38px;
            }
          }
        }
      }
      .btn-gnavi.open span:nth-of-type(1) {
        transform: translateY(10px) rotate(-45deg);
      }
      .btn-gnavi.open span:nth-of-type(2) {
        opacity: 0;
      }
      .btn-gnavi.open span:nth-of-type(3) {
        transform: translateY(-6px) rotate(45deg);
      }

      .glnv-sp {
        display: block;
        position: fixed;
        top: 0;
        right: -500px;
        width: 60%;
        height: 100%;
        padding-top: 10vh;
        padding-left: 20px;
        padding-right: 20px;
        background-color: #fff;
        z-index: 1000;
        ul {
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid #c9c9c9;
          li {
            display:block;
          }
        }
      }
      .sp-link {
        display: block;
        padding: 10px 0;
        @include f-size(14);
        text-decoration: none;
        .icon {
          padding-right: 5px;
        }
      }
    }
  }

  // コピーライト
  .header-cr {
    position: fixed;
    top: 500px;
    right: -130px;
    transform: rotate(-90deg);
    width: 340px;
    height: 18px;
    font-family: $mincho;
    font-size: 12px;
    letter-spacing: 0.05em;
    z-index: 999;
    @include breakpoint($maxWidth) {
      right: -140px;
    }
    @include bpPc {
      right: -145px;
    }
    @include bpSp {
      display: none;
    }
  }
}


// メイン
.l-main {
  margin-top: 105px;
  margin-right: 80px;
  margin-left: 80px;
  @include breakpoint($maxWidth) {
    margin-top: 80px;
    margin-right: 60px;
    margin-left: 60px;
  }
  @include bpPc {
    margin-right: 50px;
    margin-left: 50px;
  }
  @include bpSp {
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
  }
}

// メインビジュアル
.l-main {
  // TOPのみ
  .mv-top {
    position: relative;
    background-image: url(../img/top/mv_top.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-bottom: 52.65%;
    @include bpSp {
      background-image: url(../img/top/mv_top_sp.jpg);
      background-position: center bottom;
      padding-bottom: 130%;
    }
    .mv-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      @include bpSp {
        width: 75%;
      }
    }
  }
  // TOP以外
  .mv-page {
    position: relative;
    background-image: url(../img/mv_page.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-bottom: 30%;
    @include bpPcs {
      padding-bottom: 60%;
    }
    @include bpSp {
      background-position: center bottom;
      padding-bottom: 70%;
    }
    .mv-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      color: #fff;
      line-height: 1;
      text-align: center;
      @include bpPcs {
        width: 100%;
      }
      &-en {
        display: block;
        padding-bottom: 20px;
        font-family: $Libre;
        @include f-size(20);
        @include bpPcs {
          padding-bottom: 10px;
          @include f-size(16);
        }
        @include bpSp {
          @include f-size(14);
        }
      }
      &-ja {
        display: block;
        @include f-size(40);
        @include bpPcs {
          @include f-size(32);
        }
        @include bpSp {
          @include f-size(26);
        }
      }
    }
  }
}

// ぱんくず
.breadcrumb {
  padding-top: 10px!important;
  text-align: right;
  @include f-size(12);
  letter-spacing: 0.05em;
}

// フッター
.l-footer {
  position: relative;
  margin-right: 80px;
  margin-left: 80px;
  @include breakpoint($maxWidth) {
    margin-right: 60px;
    margin-left: 60px;
  }
  @include bpPc {
    margin-right: 50px;
    margin-left: 50px;
  }
  @include bpSp {
    max-width: $baseWidth;
    margin-right: 0;
    margin-left: 0;
    padding: 0 $baseWidthPadding;
  }
  #pagetop {
    position: absolute;
    top: -100px;
    right: 0;
    transform: translateY(-50%);
    @include bpPcs {
      top: -70px;
    }
    @include bpSp {
      position: static;
      width: 10%;
    }
    img {
      display: block;
      width: 80%;
      margin-left: auto;
      @include bpPcs {
        width: 80px;
        height: 79px;
      }
      @include bpSp {
        display: block;
        width: 60px;
        height: 59px;
        margin-right: auto;
        margin-bottom: 25px;
        margin-left: auto;
      }
    }
  }
  .footer-info {
    padding: 60px 40px;
    background-color: $color2;
    @include bpSp {
      padding: 35px 0;
    }
    .m-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .info-inner {
        width: 55%;
        @include bpPcs {
          width: 100%;
          order: 1;
          text-align: center;
        }
        .footer-logo {
          display: inline-block;
          padding-bottom: 15px;
          img {
            width: 70%;
          }
        }
        .footer-text {
          padding-bottom: 20px;
        }
        .footer-btn {
          .m-btn1 {
            position: relative;
            width: 180px;
            padding: 12px;
            @include bpSp {
              padding: 6px;
            }
          }
        }
      }
      .footer-nav {
        display: flex;
        justify-content: center;
        width: 45%;
        @include bpPcs {
          width: 100%;
          padding-bottom: 30px;
        }
        &-inner {
          display: block;
          a {
            display: block;
            margin-bottom: 10px;
            @include f-size(14);
            @include bpSp {
              @include f-size(11);
            }
            @include bpSps {
              @include f-size(12);
            }
            &:hover {
              text-decoration: none;
            }
            &.link {
              padding-left: 18px;
            }
          }
          &.business {
            padding-left: 50px;
            @include bpSp {
              padding-left: 20px;
            }
            @include bpSps {
              padding-left: 45px;
            }
          }
          .child {
            display: flex;
            justify-content: space-between;
            @include bpPcs {
              justify-content: flex-start;
            }
            @include bpSps {
              display: block;
            }
          }
        }
      }
    }
  }
  .footer-copyright {
    display: block;
    padding-top: 45px;
    padding-bottom: 45px;
    @include f-size(14);
    text-align: center;
    @include bpSp {
      padding-top: 15px;
      padding-bottom: 15px;
      @include f-size(11);
    }
  }
}
