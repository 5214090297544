@charset "UTF-8";

/*
#styleguide
.m-title1

```
<h2 class="m-title1">
  <span class="m-title1-en">News</span>
  <span class="m-title1-ja">新着情報</span>
</h2>
```
*/
.m-title1 {
  text-align: left;
  @include bpSp {
    line-height: 1.8em;
  }
  &::after {
    display: block;
    margin-top: 5px;
  }
  &-en {
    color: $color1;
    font-family: $Libre;
    @include f-size(20);
    @include bpPcs {
      @include f-size(18);
    }
    @include bpSp {
      @include f-size(15);
    }
    // .m-title1-en 白色
    &.white {
      color: #fff;
    }
  }
  &-ja {
    display: block;
    font-family: $mincho;
    @include f-size(26);
    @include bpPcs {
      @include f-size(23);
    }
    @include bpSp {
      @include f-size(20);
    }
  }
}
