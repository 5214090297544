@charset "UTF-8";

/*
#overview
base
*/
/*
#colors
@$color1 #699bb4
@$color2 #ecf4f9
@$textColor #000
*/
$color1: #699bb4;
$color2: #ecf4f9;
$textColor: #000;


// font
$baseFont: 15;
$responsiveFont: 13;
$lineHeight: 1.8;
$mincho: "游明朝", YuMincho, "Noto Serif JP","ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
$Gothic: "Noto Sans JP","ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
$Libre: 'Abhaya Libre', serif;

// コンテンツ幅
$baseWidth: 1000px;
$baseWidthSp: 91.25%;
$baseWidthPadding: 4.375%;

// キャンバスサイズ
$maxWidth: 2000px;

// ブレークポイント
$bpPc: 1367px;
$bpPcs: 1024px;
$bpTb: 768px;
$bpSp: 640px;
$bpSps: 425px;


// z-index
// ↑レイヤー上
// ↓レイヤー下
// eg. z-index: z(child);
$z-index:(
  child,
  nav,
  pulldown,
  header
);
// リストを逆転させる
@function list-reverse($map) {
  $result: ();
  @for $i from length($map)*-1 through -1 {
    $result: append($result, nth($map, abs($i)), comma);
  }
  @return $result;
}
$z-index: list-reverse($z-index);

@function z($name) {
  @return index($z-index, $name);
}
