@charset "UTF-8";

/*******************************
Style Start
*******************************/
.pc-hidden {
  display: none;
  @include bpPc {
    display: inline-block;
  }
}

.pcs-hidden {
  display: none;
  @include bpSp {
    display: inline-block;
  }
}

.tb-hidden {
  display: none;
  @include bpTb {
    display: inline-block;
  }
}

.sp-hidden {
  display: inline-block;
  @include bpTb {
    display: none;
  }
}

.sps-hidden {
  display: inline-block;
  @include bpSp {
    display: none;
  }
}